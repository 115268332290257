"use strict";

import { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { IndexLink, Link } from "react-router";
import debounce from "lodash.debounce";
import $ from "jquery";
import Modal from "react-modal";

import LoginRequired from "../../components/Widgets/LoginRequired.react";
import Agreement from "../../components/Licenses/Agreement.react";

import ImgResized from "../../../components/Widgets/ImgResized.react";

import ContentModalWrapper from "../../components/Modals/ContentModalWrapper.react";
import PatientMetaEditorModal from "../../components/Patients/Editor/Modals/PatientMetaEditorModal.react";
import PrescriptionEditorModal from "../../components/Patients/Editor/Modals/PrescriptionEditorModal.react";
import MealPreferencesEditorModal from "../../components/Patients/Editor/Modals/MealPreferencesEditorModal.react";
import TastePreferencesModal from "../../components/Patients/Editor/Modals/TastePreferencesModal.react";
import FamilyEditorModal from "../../components/Patients/Editor/Modals/FamilyEditorModal.react";
import AddVisitModal from "../../components/Patients/Editor/Modals/AddVisitModal.react";
import SendInviteModal from "../../components/Modals/SendInviteModal.react";
import InfoCard from "../../components/Patients/InfoCard.react";
import Activation from "../../components/Patients/Activation.react";

import { getConfig } from "../../../utils/Env";
import { getAssetsForMeals } from "../../../utils/Meals";
import Analytics from "../../../utils/Analytics";
import { getExpirationInfo } from "../../utils/Patients";

import AuthStore from "../../../stores/AuthStore";
import ChatStore from "../../../stores/ChatStore";
import ChatActions from "../../../actions/ChatActions";

import "./Details.scss";

export default class Details extends Component {
    static contextTypes = {
        router: PropTypes.object,
        user: PropTypes.object,
        confirm: PropTypes.func,
        isMobile: PropTypes.bool,
        showUpgradeForm: PropTypes.func,
    };

    static childContextTypes = {
        patient: PropTypes.object,
        preferences: PropTypes.object,
        plans: PropTypes.array,
        patient_jid: PropTypes.string,
        chat: PropTypes.object,
        chatStatus: PropTypes.string,
        visits: PropTypes.array,
        meals: PropTypes.array,
        recipes: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,
        merchants: PropTypes.object,
        brands: PropTypes.object,
        locations: PropTypes.object,
        showEditMeta: PropTypes.func,
        showEditConditions: PropTypes.func,
        showEditEnergyNeeds: PropTypes.func,
        showEditMealPreferences: PropTypes.func,
        showEditMealDetails: PropTypes.func,
        showEditPrescription: PropTypes.func,
        showEditTastePreferences: PropTypes.func,
        showEditFamilyMembers: PropTypes.func,
        ensureDateRangeLoaded: PropTypes.func,
        onChangePatient: PropTypes.func,
        onChatStart: PropTypes.func,
        sendPatientInvite: PropTypes.func,
        reactivatePatient: PropTypes.func,
    };

    constructor(props) {
        super(props);

        let chat = null;
        const chatStatus = ChatStore.getStatus();

        this.state = {
            loading: true,
            patient: null,
            patient_jid: null,

            showEditMeta: false,

            visits: [], // Any extra visits we just created.
            chat,
            chatStatus,

            meals: [],
            plans: [],
            mealsStartDate: null,
            mealsEndDate: null,
            recipes: {},
            details: {},
            foods: {},
            merchants: {},
            brands: {},
            locations: {},
        };

        this.savePatient = debounce(this.savePatient, 1000);
    }

    getChildContext = () => {
        const {
            patient,
            patient_jid,
            visits,
            meals,
            plans,
            recipes,
            details,
            foods,
            merchants,
            brands,
            locations,
            chat,
            chatStatus,
        } = this.state;

        return {
            patient,
            preferences: patient?.preferences,
            plans,
            patient_jid,
            chat,
            chatStatus,
            visits,
            meals,
            recipes,
            details,
            foods,
            merchants,
            brands,
            locations,

            showEditMeta: this.showEditMeta,
            showEditConditions: this.showEditConditions,
            showEditEnergyNeeds: this.showEditEnergyNeeds,
            showEditMealPreferences: this.showEditMealPreferences,
            showEditMealDetails: this.showEditMealDetails,
            showEditPrescription: this.showEditPrescription,
            showEditTastePreferences: this.showEditTastePreferences,
            showEditFamilyMembers: this.showEditFamilyMembers,
            ensureDateRangeLoaded: this.ensureDateRangeLoaded,

            onChangePatient: this.onChangePatient,
            onChatStart: this.onChatStart,
            sendPatientInvite: this.sendPatientInvite,
            reactivatePatient: this.reactivatePatient,
        };
    };

    componentDidMount = () => {
        ChatStore.addChangeListener(this.onChatStoreChange);
        this.loadPatient(this.context.user);
    };

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (!this.context.user && nextContext.user) {
            this.loadPatient(nextContext.user);
        }
    };

    componentWillUnmount = () => {
        ChatStore.removeChangeListener(this.onChatStoreChange);
    };

    synchronizeAssets = () => {
        const { meals } = this.state;

        getAssetsForMeals(meals).then(({ recipes, details, foods, merchants, brands, locations }) => {
            this.setState({ recipes, details, foods, merchants, brands, locations });
        });
    };

    onChatStoreChange = () => {
        const chatStatus = ChatStore.getStatus();
        let { chat, patient_jid } = this.state;

        // Only if the patient has a JID will be possibly have some messages, so we don't need
        // to initialize the patient's chat from here, just to check to see if they have sent us messages
        // which would be impossible because they don't have a patient_jid ;)
        if (patient_jid) {
            chat = ChatStore.getChatWithJid(patient_jid);

            if (chat && chatStatus == "online" && chatStatus !== this.state.chatStatus) {
                ChatActions.loadMessagesBefore(chat.with); // Load some messages
            }
        }

        this.setState({ chat, chatStatus });
    };

    loadPatient = (user) => {
        const { params, location } = this.props;
        const { router, confirm } = this.context;

        // No sense trying to load anything if the user isn't logged in.
        if (!(user && user.links && user.links.patients)) {
            return;
        }

        const url =
            getConfig("users_api") + user.links.patients.href + "/" + params.uuid + "?embed=meals,plans,report,notifications";

        AuthStore.fetch(url).then(
            (response) => {
                let { chat, chatStatus } = this.state;

                // ensure our messages with this patient are loaded if there are any
                if (response.jid) {
                    chat = ChatStore.getChatWithJid(response.jid);
                    ChatActions.loadMessagesBefore(chat.with);
                }

                let meals = [];
                let plans = [];

                if (response.meals) {
                    meals = response.meals;
                    delete response.meals;
                }

                if (response.plans) {
                    plans = response.plans;
                    delete response.plans;
                }

                this.setState(
                    {
                        patient: response,
                        meals,
                        plans,
                        patient_jid: response.jid,
                        loading: false,
                        chat,

                        // We know the embed=meals gives us 1 month centered on right now.
                        mealsStartDate: moment().subtract(2, "weeks"),
                        mealsEndDate: moment().add(2, "weeks"),
                    },
                    () => {
                        this.synchronizeAssets();

                        // Is there the sendInvite query parameter present? Trigger the sendPatientInvite function.
                        if (location.query.sendInvite) {
                            this.sendPatientInvite();

                            delete location.query.sendInvite;

                            router.push(location);
                        }

                        if (!response.active) {
                            return confirm(
                                `${response.first_name} ${response.last_name} is currently deactivated. Would you like to reactivate?`,
                                (accept) => this.reactivatePatient(),
                                (reject) => false,
                                { acceptText: "Yes, reactivate", rejectText: "Not now" },
                            );
                        }

                        const { expired } = getExpirationInfo(response.active_until, response.expiration);

                        if (expired) {
                            return confirm(
                                `${response.first_name} ${response.last_name}'s EatLove Subscription Has Expired.`,
                                (accept) => this.showEditMeta(),
                                (reject) => false,
                                { acceptText: "Renew", rejectText: "Not Now" },
                            );
                        }
                    },
                );
            },
            (error) => {
                this.setState({ patientNotFound: true, loading: false });
            },
        );
    };

    onChatStart = (patient_jid) => {
        const chat = ChatStore.getChatWithJid(patient_jid);

        this.setState({ patient_jid, chat });
    };

    processLoadedMeals = (elements) => {
        const { state } = this;

        elements.forEach((meal) => {
            const existingMeal = state.meals.find((m, i) => {
                return m && meal && m.uuid === meal.uuid;
            });

            // Don't reload updating or dirty meals, that just means that the server hasn't gotten to them yet
            // or we haven't sent them to the server yet.
            if (existingMeal) {
                state.meals[state.meals.indexOf(existingMeal)] = meal;
            } else {
                state.meals.push(meal);
            }
        });

        return state.meals;
    };

    processLoadedPlans = (elements) => {
        const { state } = this;

        elements.forEach((plan) => {
            const existingPlan = state.plans.find((p, i) => {
                return p && plan && p.uuid === plan.uuid;
            });

            // Don't reload updating or dirty plans, that just means that the server hasn't gotten to them yet
            // or we haven't sent them to the server yet.
            if (existingPlan) {
                state.plans[state.plans.indexOf(existingPlan)] = plan;
            } else {
                state.plans.push(plan);
            }
        });

        return state.plans;
    }

    loadMealsByDateRange = (startDate, endDate) => {
        const { patient } = this.state;

        const url = getConfig("users_api") + patient.links.meals.href;
        const query = {
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
        };

        return AuthStore.fetch({ url, query }).then(
            (results) => {
                this.setState({ meals: this.processLoadedMeals(results.elements) }, this.synchronizeAssets);
            },
            (error) => false,
        );
    };

    loadPlansByDateRange = (dateStart, dateEnd) => {
        const { patient } = this.state;

        const url = getConfig("users_api") + patient.links.plans.href;
        const query = {
                startDate: moment(dateStart).format('YYYY-MM-DD'),
                endDate: moment(dateEnd).format('YYYY-MM-DD'),
        };
    
        return AuthStore.fetch({url, query}).then((result) => this.setState({plans: this.processLoadedPlans(result.elements)}));
    }

    ensureDateRangeLoaded = (newDateStart, newDateEnd) => {
        let { mealsStartDate, mealsEndDate } = this.state;

        newDateStart = moment(newDateStart);
        newDateEnd = moment(newDateEnd);

        // Is new date start BEFORE our current date start? Load from new date start to old date start.
        // Is new date end AFTER our current date end? Load from old date end to new date end.
        const needNewDateStart = !mealsStartDate || (mealsStartDate && newDateStart.isBefore(mealsStartDate, "day")),
            needNewDateEnd = !mealsEndDate || (mealsEndDate && newDateEnd.isAfter(mealsEndDate, "day"));

        if (needNewDateStart && needNewDateEnd) {
            // Reload everything between the new date start to end.
            mealsStartDate = newDateStart;
            mealsEndDate = newDateEnd;

            // newDateEnd can never be more than 2 weeks after newDateStart
            if (mealsEndDate.isAfter(moment(mealsStartDate).add(2, "weeks"))) {
                mealsEndDate = moment(mealsStartDate).add(2, "weeks");
            }

            this.setState({ mealsStartDate, mealsEndDate });

            this.loadMealsByDateRange(newDateStart, newDateEnd);
            this.loadPlansByDateRange(newDateStart, newDateEnd);
        } else if (needNewDateStart && !needNewDateEnd) {
            let oldDateStart = mealsStartDate;

            mealsStartDate = newDateStart;

            // newDateEnd can never be more than 2 weeks after newDateStart
            if (oldDateStart.isAfter(moment(mealsStartDate).add(2, "weeks"))) {
                oldDateStart = moment(mealsStartDate).add(2, "weeks");
            }

            this.setState({ mealsStartDate });

            // Reload everything from new date start to old date start
            this.loadMealsByDateRange(newDateStart, oldDateStart);
            this.loadPlansByDateRange(newDateStart, oldDateStart);
        } else if (!needNewDateStart && needNewDateEnd) {
            let oldDateEnd = mealsEndDate;

            mealsEndDate = newDateEnd;

            // oldDateEnd can never be more than 2 weeks before mealsEndDate
            if (oldDateEnd.isBefore(moment(mealsStartDate).subtract(2, "weeks"))) {
                oldDateEnd = moment(mealsStartDate).subtract(2, "weeks");
            }

            this.setState({ mealsEndDate });

            // Reload everything from old date end to new date end
            this.loadMealsByDateRange(oldDateEnd, newDateEnd);
            this.loadPlansByDateRange(oldDateEnd, newDateEnd);
        }

        return new Promise((accept) => accept());
    };

    savePatient = () => {
        const { patient } = this.state;
        const { confirm } = this.context;

        // Omit the stuff from the patient record that's read-only. E.g.: we can't change the uuid
        const { following, ...rest } = patient;

        this.setState({ working: true });

        // Save the record
        AuthStore.fetch(getConfig("users_api") + patient.links.self.href, {
            method: "POST",
            headers: { "Content-Type": "application/json; schema=patient/1" },
            body: JSON.stringify({ following }),
        }).then(
            (response) => {
                // Notify the parent that there's been a change and
                this.setState({ patient, working: false, lastSaved: moment() });

                // Reset the lastSaved after 3 seconds
                setTimeout(() => {
                    this.setState({ lastSaved: null });
                }, 3000);
            },
            (error) => {
                if (error && error.message) {
                    return confirm(
                        <div>
                            <p>Very sorry, but an error occurred saving these changes.</p>
                            <pre>
                                {error.field} {error.message}
                            </pre>
                        </div>,
                        (accept) => false,
                        (reject) => false,
                        { rejectText: "" },
                    );
                }

                this.setState({ working: false, error: error.message });
            },
        );
    };

    sendPatientInvite = () => {
        const { patient } = this.state;

        this.setState({ working: true, sendInvite: true });

        AuthStore.fetch(getConfig("users_api") + patient.links["send-invite"].href, {
            method: "POST", // no post body. This is a procedural resource without parameters
        }).then(
            (response) => {
                patient.invite_sent = moment().format();

                this.setState({
                    working: false,
                    patient,
                });

                Analytics.sendPatientInvite({
                    "Patient UUID": patient.uuid,
                });
            },
            (error) => {
                this.setState({
                    working: false,
                    error: error.message,
                });
            },
        );
    };

    reactivatePatient = async () => {
        const { confirm } = this.context;
        const { patient } = this.state;

        try {
            const response = await AuthStore.fetch(getConfig("users_api") + patient.links.activate.href, {
                method: "POST",
            });

            if (response.message === "Patient reactivated") {
                patient.active = 1;
                patient.activated_date = moment().format();

                confirm(
                    <p>Client has been re-activated.</p>,
                    () => false,
                    () => false,
                    { rejectText: "" },
                );
            }

            if (response.message === "Invitation sent") {
                patient.invite_sent = moment().format();
                patient.invite_accepted = null;
                patient.active = 1;

                confirm(
                    <p>Client has been re-invited.</p>,
                    () => false,
                    () => false,
                    { rejectText: "" },
                );
            }

            this.onChangePatient(patient);
            this.setState({ working: false });
        } catch (error) {
            if (error && error.message === "Patient limit reached") {
                const { showUpgradeForm } = this.context;

                showUpgradeForm({ feature: "create_patient" });
                return;
            }

            confirm(
                <p>Could not reactivate: {error.message}</p>,
                () => this.setState({ working: false }),
                () => false,
                { rejectText: "" },
            );
        }
    };

    onChangePatient = (patient, saved = true) => {
        this.setState({ patient }, () => {
            !saved && this.savePatient(); // only save if the parent didnt save
        });
    };

    toggleFollowing = () => {
        const { patient } = this.state;

        patient.following = !patient.following;

        this.setState({ patient }, this.savePatient);
    };

    closeModal = () => {
        const { router } = this.context;
        const { pathname, query, hash } = this.props.location;

        delete query.editMeta;
        delete query.editConditions;
        delete query.editMeals;
        delete query.editTaste;
        delete query.editMealDetails;
        delete query.editPrescription;
        delete query.addVisit;
        delete query.editEnergy;
        delete query.editCarePlan;
        delete query.editFamily;

        router.push({ pathname, query, hash });

        this.setState({ sendInvite: false });
    };

    showEditMeta = () => {
        const { patient } = this.state;
        const { router, confirm } = this.context;
        const { pathname, query, hash } = this.props.location;

        if (!patient.active) {
            return confirm(
                "Inactive patients cannot be edited. Please re-activate to make changes",
                () => false,
                () => false,
                { rejectText: "" },
            );
        }

        query.editMeta = 1;
        router.push({ pathname, query, hash });
    };

    showEditConditions = () => {
        const { patient } = this.state;
        const { router, confirm } = this.context;
        const { pathname, query, hash } = this.props.location;

        if (!patient.active) {
            return confirm(
                "Inactive patients cannot be edited. Please re-activate to make changes",
                () => false,
                () => false,
                { rejectText: "" },
            );
        }

        query.editConditions = 1;
        router.push({ pathname, query, hash });
    };

    showEditEnergyNeeds = () => {
        const { router } = this.context;
        const { pathname, query, hash } = this.props.location;

        query.editEnergy = 1;
        router.push({ pathname, query, hash });
    };

    showEditMealPreferences = () => {
        const { router } = this.context;
        const { pathname, query, hash } = this.props.location;

        query.editMeals = 1;
        router.push({ pathname, query, hash });
    };

    showEditMealDetails = () => {
        const { router } = this.context;
        const { pathname, query, hash } = this.props.location;

        query.editMealDetails = 1;
        router.push({ pathname, query, hash });
    }

    showEditPrescription = () => {
        const { router } = this.context;
        const { pathname, query, hash } = this.props.location;

        query.editPrescription = 1;
        router.push({ pathname, query, hash });
    };

    showEditTastePreferences = () => {
        const { router } = this.context;
        const { pathname, query, hash } = this.props.location;

        query.editTaste = 1;
        router.push({ pathname, query, hash });
    };

    showEditFamilyMembers = () => {
        const { router } = this.context;
        const { pathname, query, hash } = this.props.location;

        query.editFamily = 1;
        router.push({ pathname, query, hash });
    };

    onAddVisit = (visit) => {
        const { visits } = this.state;

        visits.push(visit);

        this.setState({ visits }, this.closeModal);
    };

    renderEditMetaModal = () => {
        const { patient } = this.state;
        const { query } = this.props.location;

        if (!patient || !query.editMeta) {
            return null;
        }

        return (
            <PatientMetaEditorModal
                patient={patient}
                onChangePatient={this.onChangePatient}
                closeModal={this.closeModal}
            />
        );
    };

    renderEditConditionsModal = () => {
        const { patient } = this.state;
        const { query } = this.props.location;

        if (!patient || !query.editConditions) {
            return null;
        }

        return (
            <PrescriptionEditorModal
                patient={patient}
                onChangePatient={this.onChangePatient}
                closeModal={this.closeModal}
            />
        );
    };

    renderEditEnergyNeedsModal = () => {
        const { patient } = this.state;
        const { query } = this.props.location;

        if (!patient || !query.editEnergy) {
            return null;
        }

        return (
            <PrescriptionEditorModal
                patient={patient}
                includeConditions={false}
                onChangePatient={this.onChangePatient}
                closeModal={this.closeModal}
            />
        );
    };

    renderEditMealPreferencesModal = () => {
        const { patient } = this.state;
        const { query } = this.props.location;

        if (!patient || !query.editMeals) {
            return null;
        }

        return (
            <MealPreferencesEditorModal
                patient={patient}
                onChangePatient={this.onChangePatient}
                closeModal={this.closeModal}
            />
        );
    };

    renderEditTastePreferencesModal = () => {
        const { patient } = this.state;
        const { query } = this.props.location;

        if (!patient || !query.editTaste) {
            return null;
        }

        return (
            <TastePreferencesModal
                patient={patient}
                onChangePatient={this.onChangePatient}
                closeModal={this.closeModal}
            />
        );
    };

    renderEditMealDetails = () => {
        const { patient } = this.state;
        const { query } = this.props.location;

        if (!patient || !query.editMealDetails) {
            return null;
        }

        return (
            <PrescriptionEditorModal
                patient={patient}
                includeConditions={false}
                includeEnergy={false}
                includeMealDetails={true}
                onChangePatient={this.onChangePatient}
                closeModal={this.closeModal}
            />
        );
    };

    renderEditPrescriptionModal = () => {
        const { patient } = this.state;
        const { query } = this.props.location;

        if (!patient || !query.editPrescription) {
            return null;
        }

        return (
            <PrescriptionEditorModal
                patient={patient}
                includeConditions={false}
                includeEnergy={false}
                includeMealDetails={false}
                onChangePatient={this.onChangePatient}
                closeModal={this.closeModal}
            />
        );
    };

    renderEditFamilyModal = () => {
        const { patient } = this.state;
        const { query } = this.props.location;

        if (!patient || !query.editFamily) {
            return null;
        }

        return (
            <FamilyEditorModal patient={patient} onChangePatient={this.onChangePatient} closeModal={this.closeModal} />
        );
    };

    renderAddVisitModal = () => {
        const { patient } = this.state;
        const { query } = this.props.location;

        if (!patient || !query.addVisit) {
            return null;
        }

        return <AddVisitModal patient={patient} onAddVisit={this.onAddVisit} closeModal={this.closeModal} />;
    };

    renderSendInviteModal = () => {
        const { patient } = this.state;
        const { sendInvite, working, error } = this.state;

        if (!sendInvite) {
            return null;
        }

        return <SendInviteModal closeModal={this.closeModal} error={error} patient={patient} working={working} />;
    };

    onCompleteModalAction = () => {
        this.refs.recommend && this.refs.recommend.generateMorePlans();
    };

    showPatientCardModal = () => {
        this.setState({ isPatientCardOpen: true });
    };

    closePatientCardModal = () => {
        this.setState({ isPatientCardOpen: false });
    };

    renderPatientCardModal = () => {
        const { patient, working, isPatientCardOpen } = this.state;

        if (!isPatientCardOpen) {
            return;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.closePatientCardModal}
                closeModal={this.closePatientCardModal}
                contentLabel="Patient Information"
                className="patient-card-modal"
                overlayClassName="patient-card-overlay"
                closeTimeoutMS={250}
            >
                <div className="patient-card-modal-container">
                    <header>
                        <button className="close-modal-btn" onClick={this.closePatientCardModal}>
                            <i className="icon-close-x" />
                        </button>
                    </header>

                    <InfoCard
                        patient={patient}
                        working={working}
                        sendPatientInvite={this.sendPatientInvite}
                        showEditMeta={this.showEditMeta}
                        reactivatePatient={this.reactivatePatient}
                        closePatientCardModal={this.closePatientCardModal}
                    />

                    <Activation
                        patient={patient}
                        toggleFollowing={this.toggleFollowing}
                        onChangePatient={this.onChangePatient}
                    />
                </div>
            </Modal>
        );
    };

    render() {
        const { loading, patient, patientNotFound, lastSaved, visits, working, chat } = this.state;
        const { location } = this.props;
        const { user, router, isMobile } = this.context;

        if (!(user && user.links && user.links.practice && user.dietitian)) {
            return <LoginRequired user={user} />;
        }

        const { practice = {} } = user;

        if (user.practice_type === "dietetics" && user.agreement_version !== "10.2017") {
            return (
                <div className="patient-details-agreement">
                    <Agreement onAccept={() => $(window).scrollTop(0)} />
                </div>
            );
        }

        if (loading) {
            return (
                <div className="patient-details-loading">
                    <h1>Loading...</h1>

                    <i className="icon-spinner" />
                </div>
            );
        }

        if (!loading && !patient) {
            return (
                <div className="patient-details-loading">
                    <h1>Not Found</h1>

                    <i className="icon-remove-circle-outline" />
                </div>
            );
        }

        const showMessageLink = !practice.inhibit_jabber;

        const navOpts = [
            { value: `/clients/${patient.uuid}`, label: "Activity" },
            showMessageLink
                ? {
                      value: `/clients/${patient.uuid}/messages`,
                      label: "Messages",
                      badge: chat ? chat.totals.unread : null,
                      fullPage: true,
                  }
                : null,
            { value: `/clients/${patient.uuid}/meals`, label: "Meal Planning" },
            { value: `/clients/${patient.uuid}/log`, label: "Daily Log" },
            { value: `/clients/${patient.uuid}/notes`, label: "Notes" },
            {
                value: `/clients/${patient.uuid}/nutrition`,
                label: patient.practice_type === "dietetics" ? "Prescription" : "Nutrition",
            },
            { value: `/clients/${patient.uuid}/preferences`, label: "Preferences" },
            { value: `/clients/${patient.uuid}/family`, label: "Family" },
            {
                value: `/clients/${patient.uuid}/care-plan`,
                label: patient.practice_type === "dietetics" ? "Care Plan" : "Lifestyle Plan",
            },
            { value: `/clients/${patient.uuid}/settings`, label: "Settings" },
        ].filter((v) => v);

        // What page are we currently on?
        let navSelected = navOpts.find((opt) => opt.value === location.pathname) || {
            value: `/clients/${patient.uuid}/activity`,
        };

        return (
            <div className="patient-details">
                <div className="patient-details-wrapper">
                    <div className="patient-page-nav" data-full-page={navSelected.fullPage}>
                        <header onClick={this.showPatientCardModal}>
                            <Link to="/clients">
                                <button className="back-to-search-btn">
                                    <i className="icon-chevron-left" />
                                </button>
                            </Link>

                            <p>
                                {patient.image ? (
                                    <ImgResized className="headshot" src={patient.image} width={100} height={100} />
                                ) : null}
                                {!patient.image ? (
                                    <i
                                        className={
                                            patient.gender === "male" ? "headshot icon-male3" : "headshot icon-female3"
                                        }
                                    />
                                ) : null}
                                {patient.first_name} {patient.last_name}
                            </p>
                        </header>

                        <InfoCard
                            patient={patient}
                            showEditMeta={this.showEditMeta}
                            sendPatientInvite={this.sendPatientInvite}
                            reactivatePatient={this.reactivatePatient}
                        />

                        <section className="nav-links">
                            <ul>
                                {navOpts.map((opt, i) => (
                                    <li key={i}>
                                        <IndexLink activeClassName="active" to={opt.value}>
                                            {opt.label}
                                            {opt.badge ? <span className="nav-badge">{opt.badge}</span> : null}
                                        </IndexLink>
                                    </li>
                                ))}
                            </ul>
                        </section>

                        <footer>
                            <Activation
                                patient={patient}
                                toggleFollowing={this.toggleFollowing}
                                onChangePatient={this.onChangePatient}
                            />
                        </footer>
                    </div>

                    <div className="child-content">
                        <ContentModalWrapper
                            profile={patient}
                            showPdfPrint={true}
                            editableParticipants={false}
                            onCompleteAction={this.onCompleteModalAction}
                        >
                            {patient ? this.props.children : null}
                        </ContentModalWrapper>
                    </div>

                    {this.renderEditMetaModal()}
                    {this.renderEditConditionsModal()}
                    {this.renderEditEnergyNeedsModal()}
                    {this.renderEditMealPreferencesModal()}
                    {this.renderEditTastePreferencesModal()}
                    {this.renderEditMealDetails()}
                    {this.renderEditPrescriptionModal()}
                    {this.renderAddVisitModal()}
                    {this.renderSendInviteModal()}
                    {this.renderEditFamilyModal()}
                    {this.renderPatientCardModal()}
                </div>
            </div>
        );
    }
}
