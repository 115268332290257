'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import TryItOutForm from '../../../components/TryItOut/TryItOutForm.react';
import ImgResized from '../../../components/Widgets/ImgResized.react';

import UserActions from '../../../actions/UserActions';
import UserStore from '../../../stores/UserStore';
import allConditions from '../../../tables/conditions.js';

import './DashboardEmptyWizard.scss';
import '../../pages/Patients/Search.scss';

export default class DashboardEmptyWizard extends Component {
    static contextTypes = {
        totalRecipes: PropTypes.number,

        location: PropTypes.object,
        router: PropTypes.object,

        user: PropTypes.object,
    };


    render() {
        const { user, location, totalRecipes } = this.context;
        const { showTryItOutModal, modalRef } = this.props;

        if (!user) {
            return <span />
        }

        const { practice_type } = user;
        const excludeConditions = ['Osteoporosis', 'CKD Stage 1-2', 'Heart Failure'];

        if (practice_type === 'dietetics') {
            let conditionsList = allConditions.filter(c => c.on_pro && !excludeConditions.includes(c.name));

            return (
                <div className="dashboard-empty-wizard">
                    <div className="graffiti">
                        <ImgResized className="clipboard-apple" src="https://static.chewba.info/images/clipboard-apple-icon.png" />
                        <h1>Get Started with Meal Planning</h1>
                        <h2>Generate a customized meal plan in just a few clicks.</h2>
                        <ImgResized className="pointing-arrow" src="https://static.chewba.info/images/pointing-arrow-icon.png" />

                        <p>For even more advanced personalization, start with creating a patient record first.
                            <br /><Link to="/clients?create=1">Create a patient record.</Link></p>

                        <p className="training">Want some training instead? You can earn 2 CEUs by attending our<br /><a target="_blank" href="https://eatlove.teachable.com/p/eatlove-pro-101/">EatLove PRO 101</a> course on Teachable</p>
                    </div>

                    <div className="try-it-module">
                        <header>Quick Start Meal Plan</header>
                        {totalRecipes > 0 ?
                            <Link to="/search"><div className="total-recipes"><em>{totalRecipes}</em>Recipes Available</div></Link>
                        : null}

                        <h2>Show me a Plan For</h2>
                        <TryItOutForm ref={modalRef} location={location}
                                    storageKey={`pro-try-it-out-${user.uuid}`} tryItMode="pro" />
                        <footer>
                            <button className="get-meal-plan-btn" onClick={() => showTryItOutModal(false)}>
                                REVIEW MEAL SCHEDULE
                            </button>
                        </footer>  

                    </div>
                </div>
            );
        }

        return (
            <div className="pro-patients">
                <section className="top-row empty">
                    <h1>Welcome to EatLove Fitness, {user.name || user.email}</h1>

                    <div className="create-new-patient">
                        <i className="icon-search-patient" />
                        <i className="icon-plus-thick" />
                        <h3>Add Your First Client</h3>

                        <Link to="/clients?create=1"><button>Add Client</button></Link>
                    </div>

                    <p>Don't have a client in mind? Try adding your personal email address as a test client.</p>

                    <p className="training"> Want some training instead? Attend our&nbsp;
                    <a target="_blank" href="https://eatlove.teachable.com/p/eatlove-fitness-introductory-training/">
                    EatLove PRO Fitness Getting Started</a> course on Teachable.</p>
                </section>
            </div>
        );
    }
}
