'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Prescription from '../Prescription.react';
import EditorModal from '../../../Modals/EditorModal.react';
import AvoidanceSelect from '../../../../../components/Preferences/AvoidanceSelect.react';
import TastePreferences from '../../../../../components/Preferences/TastePreferences.react';

import ConfigWarning from '../../../../../components/Widgets/ConfigWarning.react';

import AuthStore from '../../../../../stores/AuthStore';
import { getConfigurationIssues } from '../../../../utils/Patients';
import { getConfig } from '../../../../../utils/Env';
import Analytics from '../../../../../utils/Analytics';

import '../DietPreferences.scss';
import '../PatientForm.scss';
import { titleCase } from '../../../../../utils/TextUtil';
import { isSameValue } from '../../../../../utils/Util';

export default class TastePreferencesModal extends Component {
    static propTypes = {
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const { patient = {} } = props;

        const diets = (patient.preferences && patient.preferences.diets || []).slice(0);
        const avoidances = (patient.preferences && patient.preferences.avoidances || []).slice(0)
        const exclude_foods = (patient.preferences && patient.preferences.exclude_foods || []).slice(0);
        const prefer_milks = (patient.preferences && patient.preferences.prefer_milks || []).slice(0);
        const prefer_rices = (patient.preferences && patient.preferences.prefer_rices || []).slice(0);
        const prefer_cuisines = (patient.preferences && patient.preferences.prefer_cuisines || []).slice(0);
        const currentPreferences = JSON.parse(JSON.stringify(patient.preferences));

        this.state = {
            patient: JSON.parse(JSON.stringify(props.patient)),
            currentPreferences,
            diets,
            avoidances,
            exclude_foods,
            prefer_milks,
            prefer_rices,
            prefer_cuisines,

            working: false,

            dirty: false,
            mode: 'taste-preferences'
        };
    }

    validate = () => {
        const { patient, diets, avoidances, exclude_foods, prefer_milks, prefer_rices, prefer_cuisines } = this.state;

        // Get configuration issues for the current selection
        const { errors } = getConfigurationIssues({
            ...patient,
            preferences: {
                ...patient.preferences,
                diets,
                avoidances,
                exclude_foods,
                prefer_milks,
                prefer_rices,
                prefer_cuisines,
            }
        });

        if (errors.length) {
            this.setState({error: errors[0]});

            return false;
        }

        return true;
    }

    mutate = (patient) => {
        const { diets, avoidances, exclude_foods, prefer_milks, prefer_rices, prefer_cuisines } = this.state;

        // Mutate the patient record
        patient.preferences = patient.preferences || {};

        patient.preferences.diets = diets;
        patient.preferences.avoidances = avoidances;
        patient.preferences.exclude_foods = exclude_foods;
        patient.preferences.prefer_milks = prefer_milks;
        patient.preferences.prefer_rices = prefer_rices;
        patient.preferences.prefer_cuisines = prefer_cuisines;
        patient.completed = patient.completed || [];

        if (!patient.completed.includes('taste')) {
            patient.completed.push('taste');
        }

        return patient;
    }

    savePatientPrefs = (patient) => {
        // Omit the stuff from the patient record that's read-only. E.g.: we can't change the uuid
        const { completed, preferences } = patient;
        const { onChangePatient, closeModal } = this.props;
        const { currentPreferences } = this.state;

        this.setState({working: true, error: null});

        // Save the record
        return AuthStore.fetch(getConfig('users_api') + patient.links.self.href, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=patient/1'},
            body: JSON.stringify({completed, preferences}),
        }).then(
            response => {
                const preferencesUpdated = Object.keys(currentPreferences)
                    .filter(key => !isSameValue(currentPreferences[key], preferences[key]));

                Analytics.updatePatientTastePreferences({
                    'Patient UUID': patient.uuid,
                    'Preferences': preferencesUpdated ? preferencesUpdated.map(p => titleCase(p)) : null
                });

                // Notify the parent that there's been a change, then close the modal
                onChangePatient(patient);
                closeModal();
            },
            error => {
                this.setState({working: false, error: error.message});
            }
        );
    }

    savePatientPrescription = (patient) => {
        // Omit the stuff from the patient record that's read-only. E.g.: we can't change the uuid
        const { next_update, completed, preferences } = patient;
        const { onChangePatient, closeModal } = this.props;

        this.setState({working: true, error: null});

        // Save the record
        return AuthStore.fetch(getConfig('users_api') + patient.links.self.href, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=patient/1'},
            body: JSON.stringify({next_update, completed, preferences}),
        }).then(
            response => {
                // Notify the parent that there's been a change, then close the modal
                onChangePatient(patient);

                closeModal();

                Analytics.updatePatientNutrition({
                    'Patient UUID': patient.uuid
                });
            },
            error => {
                this.setState({working: false, error: error.message});
            }
        );
    }

    onSavePatient = () => {
        const { mode, patient } = this.state;

        if (mode === 'prescription') {
            const rxError = this.refs.prescription.validate();

            if (rxError !== true) {
                this.setState({error: rxError});
                return;
            }

            // Mutate the patient with new values from both states
            this.mutate(patient);
            this.refs.prescription.mutate(patient);

            this.savePatientPrescription(patient);

            return;
        }

        if (!this.validate()) {
            return;
        }

        this.mutate(patient);
        this.savePatientPrefs(patient);
    }

    closeModal = (page) => {
        const { closeModal } = this.props;

        if (this.state.dirty) {
            this.context.confirm(
                <div className="confirm-discard-changes">
                    <h6>Discard changes?</h6>
                    <p>You&apos;ve made changes to this client, are you sure you want to discard those changes?</p>
                </div>,
                closeModal,
                () => false,
                {
                    acceptText: 'Discard Changes'
                }
            );

            return;
        }

        closeModal();
    }

    onChangeProfile = (profile) => {
        this.setState({
            diets: profile.preferences.diets,
            avoidances: profile.preferences.avoidances,
            exclude_foods: profile.preferences.exclude_foods,
            dirty: true,
        });
    }

    onChangePreferences = (user = null, preferences = null) => {
        const { prefer_milks, prefer_rices, prefer_cuisines } = preferences;

        this.setState({prefer_milks, prefer_rices, prefer_cuisines, dirty: true});
    }

    render() {
        const { patient, mode, error, working, diets, avoidances, exclude_foods, alert } = this.state;

        const profile = {
            ...patient,
            preferences: {
                ...patient.preferences,
                diets,
                avoidances,
                exclude_foods,
            }
        };

        const modalTitle = mode === 'taste-preferences'
                         ? `${patient.first_name}'s Taste Preferences`
                         : `Confirm ${patient.first_name}'s Nutrition Prescription`;

        return (
            <EditorModal title={modalTitle} error={error}
                closeModal={this.closeModal} working={working} patient={patient}
                onSavePatient={this.onSavePatient} ref="modal">

                {mode === 'prescription' ?
                    <div className="prescription-form">
                        <Prescription ref="prescription" patient={patient} />
                    </div>
                : null}

                {mode === 'taste-preferences' ?
                    <div className="patient-form diet-preferences">
                        <AvoidanceSelect profile={profile} onChangeProfile={this.onChangeProfile}  rd_override/>
                        <TastePreferences user={profile} preferences={profile.preferences}
                            onChangePreferences={this.onChangePreferences} hideHeaderText={true} showCuisine={true} />
                        <ConfigWarning profile={profile} />

                    </div>
                : null}

            </EditorModal>
        );
    }
}
